'use client'

import classnames from 'classnames'
import React from 'react'
import styles from './styles.module.css'
import {
  MWDSUnstyledButton,
  type MWDSUnstyledButtonProps,
} from '~/mercuryWebCompat/design-system/MWDSUnstyledButton'
import {type OmitStrict} from 'type-zoo'
import MWDSLink, {type MWDSLinkBaseProps} from '../MWDSLink'
import MWDSLoadingDots from '../MWDSLoadingIndicator/MWDSLoadingDots'

type OldMWDSButtonStyle =
  | 'none'
  | 'primary'
  | 'primary-with-icon'
  | 'secondary-with-icon'
  | 'primary-dark'
  | 'secondary' // secondary: background color is pearl-grey
  | 'secondary-black' // secondary but with black text
  | 'tertiary' // tertiary: background color is off-white
  | 'tertiary-with-icon'
  | 'tertiary-white-with-icon'
  | 'tertiary-destructive'
  // one-off buttons not in the DS
  | 'attachedToInput' // custom button used in set pin
  | 'transfer-funds' // used in treasury dashboard
  | 'activity-table' // used in treasury activity table
  | 'back-to-queue' // used in application review dashboard
  | 'form-destructive' // currently used in manual transaction review form
  | 'credit-usage-graph-button'

type OldMWDSButtonSize = 'large' | 'medium' | 'small' | 'tiny' | 'none'

export type OldMWDSButtonProps = {
  buttonRef?: React.RefObject<HTMLButtonElement>
} & OldMWDSButtonBaseProps &
  MWDSUnstyledButtonProps

/**
 * @deprecated Use DSButton instead whenever possible.
 */
const OldMWDSButton = ({
  // OldMWDSButton-specific props
  buttonRef,

  // UnstyledButton props
  onClick,
  analyticsEvent,

  // OldMWDSButtonBase props
  appearDisabled,
  disabled,
  loading,
  stretch,
  buttonStyle,
  size,
  shadow,

  // HTML `<button>` props
  children,
  ...htmlButtonProps
}: OldMWDSButtonProps) => {
  return (
    <OldMWDSButtonBase
      appearDisabled={appearDisabled}
      disabled={disabled}
      loading={loading}
      stretch={stretch}
      buttonStyle={buttonStyle}
      size={size}
      shadow={shadow}
    >
      {baseProps => (
        <MWDSUnstyledButton
          ref={buttonRef}
          {...htmlButtonProps}
          onClick={onClick}
          analyticsEvent={analyticsEvent}
          disabled={disabled}
          className={classnames(
            baseProps.buttonClassNames,
            htmlButtonProps.className
          )}
        >
          {baseProps.buttonContents(children)}
        </MWDSUnstyledButton>
      )}
    </OldMWDSButtonBase>
  )
}

export default OldMWDSButton

type OldMWDSButtonLinkProps = OldMWDSButtonBaseProps &
  OmitStrict<MWDSLinkBaseProps, 'onClick' | 'size'>

/* Styled exactly like OldDSButton, but is a `<DSLink>` instead of a `<button>` */
/**
 * @deprecated Use DSButtonLink instead whenever possible.
 */
export const OldMWDSButtonLink = ({
  // OldDSButtonBase props
  appearDisabled,
  disabled,
  loading,
  stretch,
  buttonStyle,
  size,
  shadow,

  // DSLink props
  children,
  className,
  ...dsLinkProps
}: OldMWDSButtonLinkProps) => {
  return (
    <OldMWDSButtonBase
      appearDisabled={appearDisabled}
      disabled={disabled}
      loading={loading}
      stretch={stretch}
      buttonStyle={buttonStyle}
      size={size}
      shadow={shadow}
    >
      {baseProps => (
        <MWDSLink
          {...dsLinkProps}
          className={classnames(baseProps.buttonClassNames, className)}
        >
          {baseProps.buttonContents(children)}
        </MWDSLink>
      )}
    </OldMWDSButtonBase>
  )
}

// props that external users of all OldMWDSButton varieties can set
// NB: if you add something here make sure to add it to all the varieties' destructured props declarations
type OldMWDSButtonBaseProps = {
  /** whether the button looks disabled or not. It can still be clicked unless `disabled` is set */
  appearDisabled?: boolean
  /** Actually not clickable */
  disabled?: boolean
  loading?: boolean
  stretch?: boolean
  buttonStyle?: OldMWDSButtonStyle
  size?: OldMWDSButtonSize
  shadow?: boolean
}

// props that implementations of OldMWDSButtonBase pass to the Base, not set by external users of the concrete varieties
type OldMWDSButtonBaseInternalProps = {
  children: (props: OldMWDSButtonBaseRenderProps) => React.ReactNode
}

type OldMWDSButtonBaseRenderProps = {
  buttonClassNames: string
  buttonContents: (children: React.ReactNode) => React.ReactNode
}

const DEFAULT_STYLE: OldMWDSButtonStyle = 'none'

const OldMWDSButtonBase = ({
  appearDisabled,
  disabled,
  loading,
  stretch,
  buttonStyle,
  size,
  shadow,
  children,
}: OldMWDSButtonBaseProps & OldMWDSButtonBaseInternalProps) => {
  const style = buttonStyle === undefined ? DEFAULT_STYLE : buttonStyle
  const sizeToUse = size ?? 'none'

  const buttonClassNames = classnames({
    [styles['button-wrapper']]: true,
    [styles.button]: !!style,
    [styles.loading]: loading,
    [styles.stretch]: stretch,
    [styles[style]]: !!style,
    [styles.disabled]: appearDisabled ?? disabled,
    [styles[sizeToUse]]: !!sizeToUse,
    [styles.shadow]: shadow,
  })

  const buttonContents = (childrenOfConcreteClass: React.ReactNode) => (
    <div>
      {loading && (
        <MWDSLoadingDots
          onDarkBackground={
            buttonStyle === 'primary' || (buttonStyle === 'primary-dark' && true)
          }
          classNames={{wrapper: styles['loading-dotdotdot']}}
        />
      )}
      <div className={loading ? styles['children-hidden'] : undefined}>
        {childrenOfConcreteClass}
      </div>
    </div>
  )

  return children({
    buttonClassNames,
    buttonContents,
  })
}
