'use client'

import isInEU from '@segment/in-eu'
import React from 'react'
import ProvideConsentManagement from './ProvideConsentManagement'
import styles from './styles.module.css'
import {internalLinks} from '~/routing/internal-links'
import OldMWDSButton from '~/mercuryWebCompat/design-system/OldMWDSButton'
import MWDSLink from '~/mercuryWebCompat/design-system/MWDSLink'

type Props = Record<string, unknown>
type State = {
  eu: boolean
}

export default class GDPRBanner extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)

    let eu: boolean = true
    // `isInEU` uses `Intl` which some older browsers don't have.
    // Also apparently it blows up sometimes because the underlying jstz library has a bug here:
    // https://github.com/iansinnott/jstz/blob/528b77e9f827ec359cd3f08abe05ff27b597c4d5/jstz.main.js#L107
    // where they call a class constructor without adding `new`. This results in:
    // https://app.bugsnag.com/mercury-technologies/mercury-web/errors/5f37efc207c4ec001832150b?event_id=60dc46be007f5d05f50f0000&i=sk&m=oc
    // Instead of trying to patch all this up, we're just going to wrap it in a try/catch block and just pretend
    // it's an EU country to be safe if/when things go south.
    try {
      eu = isInEU()
    } catch {}
    this.state = {eu}
  }

  render() {
    return (
      <ProvideConsentManagement
        // TODO(CP-218): figure out how we want to handle MockConfig stuff
        // shouldRequireConsent={MockConfig.inEU ?? this.state.eu}
        shouldRequireConsent={this.state.eu}
      >
        {({saveConsent}) => (
          <div className={styles.wrapper}>
            <span>
              <MWDSLink
                underline="always"
                color="black"
                href={{pathname: internalLinks.legal.privacy}}
              >
                We use cookies
              </MWDSLink>{' '}
              to optimize your browsing experience. Click ‘OK’ to agree, or{' '}
              <OldMWDSButton
                analyticsEvent="gdpr/optout"
                onClick={() => {
                  saveConsent('optout')
                }}
                className={styles['optout-button']}
              >
                opt out
              </OldMWDSButton>
            </span>
            <OldMWDSButton
              analyticsEvent="gdpr/optin"
              buttonStyle="tertiary"
              size="small"
              onClick={() => {
                saveConsent('optin')
              }}
              className={styles['optin-button']}
            >
              OK
            </OldMWDSButton>
          </div>
        )}
      </ProvideConsentManagement>
    )
  }
}
