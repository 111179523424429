'use client'

import isInEU from '@segment/in-eu'
import {useParams, usePathname, useSearchParams} from 'next/navigation'
import {pick} from 'ramda'
import {useEffect} from 'react'
import {env} from '~/config/env.mjs'
import {isPreview} from '~/config/nodeEnv'
import {Analytics, _analytics} from '~/utils/Analytics'
import {loadTrackingOptoutPreference} from '~/utils/Analytics/utils'

const allowedQueryStrings = [
  'alphaCode',
  'partner_id',
  'userId',
  'userrole', // cspell:disable-line
  'userAgent',
] as const

/**
 * Filters out query strings that are sent to analytics platforms unless they're allowed by
 * `allowedQueryStrings`.
 *
 * This is necessary because otherwise we get a ton of noisy query strings we don't care about.
 *
 * @param queryString an object with the de-serialized query string
 * @returns the filtered object;
 */
export const filterUndesiredQueryStringParams = (queryString: object): object =>
  pick(allowedQueryStrings, queryString)

export function PageAnalyticsWatcher() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const params = useParams()

  // Loads the analytics library based on the user's consent
  useEffect(() => {
    const consent = loadTrackingOptoutPreference()
    if (!consent) {
      try {
        if (isInEU()) {
          return
        }
      } catch {
        // do nothing, user is in another country where we can track without explicit consent
      }
    }
    if (consent === 'optout') return
    if (isPreview) return

    _analytics.load({
      writeKey: env.NEXT_PUBLIC_SEGMENT_KEY || '',
    })
    ;(window as any).analytics = _analytics
  }, [])

  /**
   * If you are on dev and notice this is firing twice on page load, it's because of React strict mode.
   * https://reactjs.org/docs/strict-mode.html#detecting-unexpected-side-effects
   * > This is done by intentionally double-invoking the following functions...
   */
  useEffect(() => {
    if (!pathname) return
    Analytics.page({
      path: pathname,
      qs: filterUndesiredQueryStringParams(Object.fromEntries(searchParams)),
      urlParams: params,
      viewportWidth: window.innerWidth.toString(),
      url: window.location.href,
    })
  }, [params, pathname, searchParams])

  return null
}
