'use client'

import {useEffect} from 'react'
import {isProd} from '~/config/nodeEnv'

export function UserConsoleWarning() {
  useEffect(() => {
    if (isProd) {
      console.warn('%cPlease turn back!', 'color: red; font-size: 2em;')
      console.warn(
        '%cThis browser feature is for developers. If you copy-paste something here, it could give someone access to your Mercury account!',
        'font-size: 1em;'
      )
    }
  }, [])
  return null
}
