'use client'
import {QueryCache, QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {type PropsWithChildren} from 'react'

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: error => {
      // NOTE: We are not bugsnagging here like we do in mercury-web because
      // we have the error handling in the `endpoint.fetch` method
      console.error(error)
    },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      networkMode: 'online',
    },
    mutations: {
      retry: false,
    },
  },
})

export function ReactQuery(props: PropsWithChildren<unknown>) {
  const {children} = props

  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}
