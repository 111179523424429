// TODO(CP-100): implement using next/header's cookie library?
import Cookies from 'js-cookie'
import {type OptinOptout} from '~/utils/types'
import {_analytics} from '.'
import {TRACKING_OPTOUT_COOKIE_NAME} from '~/config/cookies'

export const loadTrackingOptoutPreference = (): OptinOptout | undefined => {
  const savedVal = Cookies.get(TRACKING_OPTOUT_COOKIE_NAME)
  if (savedVal === 'optin' || savedVal === 'optout') {
    return savedVal
  } else {
    return undefined
  }
}

export const saveTrackingOptoutPreference = (newPref: OptinOptout) => {
  const oldPref = loadTrackingOptoutPreference()
  Cookies.set(TRACKING_OPTOUT_COOKIE_NAME, newPref)
  void _analytics.deregister()
  // If they are newly opting out, reload the page to disable already-loaded trackers
  if (oldPref !== newPref && newPref === 'optout') {
    window.location.reload()
  }
}

/**
 * When an analytics event property's value is optional, in most situations this output is undesirable.
 *
 * analyticsEvent={
 *    optionalVal: undefined,
 * }
 *
 * and instead omitting the property entirely unless defined is the preferred format, which this function handles
 *
 * Spread the return value of this function into the event object to have the value and key omitted if the value is undefined.
 *
 * analyticsEvent={{
 *    event: 'click',
 *    properties: {
 *     ...optionalAnalyticsProperty(
 *        'optionalPropertyKey',
 *         optionalPropertyValue
 *      ),
 *    }
 * }}
 *
 * @param key the object's key to denote the value if it exists
 * @param {string | number} property the optional analytics value to be returned if it exists
 * @returns [x: string]: string | number;
 */
export const optionalAnalyticsProperty = (
  key: string,
  property?: string | number
) => {
  if (property === undefined) {
    return {}
  }

  return {
    [key]: property,
  }
}
