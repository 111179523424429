import {Component} from 'react'
import {
  loadTrackingOptoutPreference,
  saveTrackingOptoutPreference,
} from '~/utils/Analytics/utils'
import {type OptinOptout} from '~/utils/types'

type Props = {
  shouldRequireConsent: boolean
  children: (props: RenderProps) => React.ReactElement
}

type RenderProps = {
  saveConsent: (newPreferences: OptinOptout) => void
}

type State = {
  isLoading: boolean
  isConsentRequired: boolean
}

export default class ProvideConsentManagement extends Component<Props, State> {
  state = {
    isLoading: true,
    isConsentRequired: true,
  }

  render() {
    const {children} = this.props
    const {isLoading, isConsentRequired} = this.state

    if (isLoading || !isConsentRequired) {
      return null
    }

    return children({
      saveConsent: this.handleSaveConsent,
    })
  }

  componentDidMount() {
    const {shouldRequireConsent} = this.props

    let isConsentRequired = shouldRequireConsent

    const savedPref = loadTrackingOptoutPreference()
    if (savedPref !== undefined) {
      isConsentRequired = false
    }

    this.setState({
      isLoading: false,
      isConsentRequired,
    })
  }

  handleSaveConsent = (optin: OptinOptout) => {
    saveTrackingOptoutPreference(optin)
    this.setState({isConsentRequired: false})
  }
}
